/** @format */

if ("NodeList" in window && !NodeList.prototype.forEach) {
  console.info("polyfill for IE11");
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (let i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}
var lastScrollTop = 0;
(function () {
  window.onload = function () {
    /*this function add a class to header when scroll down the page
		/* and change the logo by a new file if needed depending of mockup
		*/

    // scrollDownAction(20, 'scroll');

    scrollW();
    window.addEventListener("scroll", function () {
      scrollW();
    });



  };
})();

(function () {
  // Remove empty <p> tags created by tinymce
  const paragraph = document.querySelectorAll("p") || [];
  paragraph.forEach(
    (e) => e.innerHTML.trim() === "" && e.parentNode.removeChild(e)
  );

  //tabs sections
  const tabLinks = document.querySelectorAll("ul.js-tabs-menu li a");
  if (tabLinks.length) {
  tabLinks[0].classList.add('active');
  document.getElementById(tabLinks[0].getAttribute("href").replace("#", "")).style.display = "block";

  for (let i = 0; i < tabLinks.length; i++) {
    tabLinks[i].onclick = function () {
      let target = this.getAttribute("href").replace("#", "");
      let sections = document.querySelectorAll("div.tab-content");
      let general_disclaimer = document.querySelector(".js-general_disclaimer_menus");
      
      for (let j = 0; j < sections.length; j++) {
        sections[j].style.display = "none";
      }
      document.getElementById(target).style.display = "block";
      for (let k = 0; k < tabLinks.length; k++) {
        tabLinks[k].classList.remove("active");
      }
      this.classList.add("active");
      if(target == 'minibar'){
        general_disclaimer.style.display = "none";
      }else{
        general_disclaimer.style.display = "block";
      }

      return false;
    };
  }
  }

  // hide, show, or toggle
  const show = function (elem) {
    let getHeight = function () {
      elem.style.display = "block";
      let height = elem.scrollHeight + "px";
      elem.style.display = "";
      return height;
    };
    let height = getHeight(); // get element height
    elem.classList.add("appear");
    elem.style.height = height; // update the max-height
    window.setTimeout(function () {
      elem.style.height = "";
    }, 350);
  };
  const hide = function (elem) {
    elem.style.height = elem.scrollHeight + "px";
    window.setTimeout(function () {
      elem.style.height = "0";
    }, 1);
    window.setTimeout(function () {
      elem.classList.remove("appear");
    }, 350);
  };
  const toggle = function (elem) {
    if (elem.classList.contains("appear")) {
      hide(elem);
      return;
    } else {
      show(elem);
    }
  };
  //toggle more details
  document.addEventListener(
    "click",
    function (event) {
      if (!event.target.classList.contains("toggle-btn-js")) return;
      event.preventDefault();

      let content = document.querySelector(event.target.hash);
      let allcontent = document.querySelectorAll(".toggle-content");

      if (event.target.classList.contains("active")) {
        event.target.classList.remove("active");
        //hide(content);
      } else {
        event.target.classList.add("active");
        //show(content);
      }
      // var els = document.querySelectorAll('.toggle-btn-js');
      // [].forEach.call(els, function(el, i, els) {
      //     el.addEventListener('click', function() {
      //         [].forEach.call(els, function(el) {
      //             if(el !== this) {
      //                 //hide(content);

      //             } else {
      //                 show(content);
      //             }
      //         }, this);
      //     });
      // });

      if (!content) return;
      toggle(content);
    },
    false
  );

  //animations below
  // setTimeout(function(){
  //     if(document.getElementById('home')){
  //         document.getElementsByClassName('top-content-home__uvp')[0].classList.add('show-object');
  //     }
  // }, 1000);

  const animateHTML = function () {
    let elems, windowHeight;
    let init = function () {
      elems = document.getElementsByClassName("hide-object");
      windowHeight = window.innerHeight;
      addEventHandlers();
    };
    let addEventHandlers = function () {
      window.addEventListener("scroll", checkPosition);
      window.addEventListener("resize", init);
    };
    let checkPosition = function () {
      for (let i = 0; i < elems.length; i++) {
        let posFromTop = elems[i].getBoundingClientRect().top;
        if (posFromTop - windowHeight <= 0) {
          elems[i].className = elems[i].className.replace(
            "hide-object",
            "show-object"
          );
        }
      }
    };
    return {
      init: init,
    };
  };
  animateHTML().init();

  /*** slick ***/
  // normal
  $(".sym-slides").not(".slick-initialized").slick({
    accessibility: true,
  });

  //slider with nav timer
  $(".slider-nav")
    .not(".slick-initialized")
    .slick({
      accessibility: true,
      pauseOnHover: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      dots: true,
      fade: true,
      appendDots: $(".slider-dots-box"),
      dotsClass: "slider-dots",
    });

  $(".carousel-slides").slick({
    accessibility: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1090,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          fade: true,
        },
      },
    ],
  });
  // On before slide change
  // $('.slider-nav').on('beforeChange', function(event, slick, currentSlide, nextSlide){
  // $('.slider-dots-box button').html('');
  // }).trigger('beforeChange');
  // // On before slide change
  // $('.slider-nav').on('afterChange', function(event, slick, currentSlide){
  // $('.slider-dots-box button').html('');
  //     $('.slider-dots-box .slick-active button')
  //         .html(`<svg class="progress-svg" width="40" height="40">
  //         <g transform="translate(19,19)">
  //         <circle class="circle-go" r="18" cx="0" cy="0"></circle>
  //         </g>
  //     </svg>`);
  // }).trigger('afterChange');
  /*** end slick ***/

  //fancybox
  $(".fancycopy-link").fancybox({
    wrapCSS: "fancycopy-popup",
    padding: 0
  });

  $(".fancyboxFloorplan").fancybox({
    wrapCSS: "fancy-floorplan",
    padding: 0
  });

  setTimeout(function () {
    $('#attraction-category option[value="Attractions & Entertainment"]').html(
      "Attractions"
    );
    $('#attraction-category option[value="On the Land"]').html(
      "Parks & Green Spaces"
    );
  }, 1000);

  jQuery(document).ready(function ($) {
    $(".sticky-navigation ul a[href*=\\#]:not([href=\\#])").click(function () {
      if (
        location.pathname.replace(/^\//, "") ==
          this.pathname.replace(/^\//, "") ||
        location.hostname == this.hostname
      ) {
        var target = $(this.hash);
        target = target.length
          ? target
          : $("[name=" + this.hash.slice(1) + "]");
        if (target.length) {
          $("html,body").animate(
            {
              scrollTop: target.offset().top - 200,
            },
            1000
          );
          return false;
        }
      }
    });
  });

  $(document).on("click", 'a[href^="#"]:not(.no-scroll)', function (event) {
    if (!this.getAttribute("href").trim().includes("#/booking/step-1") && this.getAttribute("href").trim() !== "#gameday" ) {
      event.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $($.attr(this, "href")).offset().top - 200,
        },
        500
      );
    }
  });

  /*** start of list navigation ***/
  jQuery(document).ready(function ($) {
    // Active Nav Link
    $("ul.list-navigation li a, ul.list-tabs-nav li a").click(function () {
      $("ul.list-navigation li a, ul.list-tabs-nav li a").removeClass("active");
      $(this).addClass("active");
    });

    //languages
    $(".language-btn").change(function (e) {
      e.preventDefault();

      let langSel = $(this).val();
      let pathname = document.location.pathname;
      let origin = document.location.origin;
      let langs = [
        "es",
        "fr",
        "de",
        "pt",
        "it",
        "ko",
        "ja",
        "ru",
        "nl",
        "zh-CN",
      ];
      for (const key in langs) {
        if (pathname.indexOf(`/${langs[key]}/`) > -1)
          pathname = pathname.replace(`/${langs[key]}/`, "");
      }

      if (langSel != "en") {
        if ($("body").attr("id") === "home")
          document.location = origin + "/" + langSel;
        else {
          let newUrl = langSel + "/" + pathname;
          newUrl = newUrl.replace("//", "/");
          document.location = origin + "/" + newUrl;
        }
      } else {
        if ($("body").attr("id") === "home") document.location = origin + "/";
        else document.location = origin + "/" + pathname;
      }
    });
  });

  $(window).scroll(function () {
    var scrollTop = $(".top-content").height() + $(".intro-copy").height() + 50;
    var headerTop = $(".header").height();

    if ($(window).scrollTop() >= scrollTop) {
      $("ul.list-navigation").css({
        position: "fixed",
        top: headerTop,
      });
    }
    if ($(window).scrollTop() < scrollTop) {
      $("ul.list-navigation").removeAttr("style");
    }
  });

  function setActiveListElements(event) {
    // Get the offset of the window from the top of page
    var windowPos = $(window).scrollTop();

    $('ul.list-navigation li a[href^="#"]').each(function () {
      var anchorId = $(this);
      var target = $(anchorId.attr("href"));

      if (target.length > 0) {
        if (
          target.position().top + $(".lists__each").outerHeight() <=
          windowPos
        ) {
          $("ul.list-navigation li a").removeClass("active");
          anchorId.addClass("active");
        }
      }
    });
  }

  $(window).scroll(function () {
    setActiveListElements();
  });
  /*** end of list navigation ***/
})();

function scrollW() {
  //check scroll down or up for header styles
  let st = window.pageYOffset || document.documentElement.scrollTop;
  const header = document.getElementById("header");

  if (header) {
  if (st > 80) {
    if (st > lastScrollTop) {
      // downscroll code
      if (!header.classList.contains("down")) {
        header.classList.add("down");
        header.classList.remove("up");
      }
      // if(!document.getElementsByClassName('countdown-cs')[0].classList.contains('down')) {
      //     document.getElementsByClassName('countdown-cs')[0].classList.add('down');
      // }
    } else {
      // upscroll code
      if (!header.classList.contains("up")) {
        header.classList.add("up");
        header.classList.remove("down");
      }
      // if(!document.getElementsByClassName('countdown-cs')[0].classList.contains('up')) {
      //     document.getElementsByClassName('countdown-cs')[0].classList.add('up')
      //     document.getElementsByClassName('countdown-cs')[0].classList.remove('down');
      // }
    }
  } else {
    if (
      header.classList.contains("up") ||
      header.classList.contains("down")
    ) {
      header.classList.remove("up");
      header.classList.remove("down");
    }
    // if(document.getElementsByClassName('countdown-cs')[0].classList.contains('up') ||  document.getElementsByClassName('countdown-cs')[0].classList.contains('down')) {
    //     document.getElementsByClassName('countdown-cs')[0].classList.remove('up');
    //     document.getElementsByClassName('countdown-cs')[0].classList.remove('up');
    // }
  }
}
  lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling

  //home animation
  var opacity =
    (document.body.offsetHeight - document.body.scrollTop) /
    document.body.offsetHeight;
  var scale =
    (document.body.offsetHeight - document.body.scrollTop) /
    document.body.offsetHeight;

  document.documentElement.style.setProperty("--headerOpacity", opacity);
  document.documentElement.style.setProperty("--headerScale", scale);
}




  //GAME DAY
  document.addEventListener('DOMContentLoaded', function () {
    searchAndClick();
  });
  
  function searchAndClick() {
    const desiredElement = document.querySelector('.js-tabs-menu a[href="#valentines-day"]');
  
    if (desiredElement) {
      desiredElement.addEventListener('click', function (event) {
        event.preventDefault(); 
        console.log('Clicked on Element');
      });
      desiredElement.click();
    } else {
      console.log('No Element in DOM');
    }
  }
  
  
