function slideNextContent(btn,className){
    btn.click(function(event) {
        let $this = $(this);
        event.preventDefault();
        $this.toggleClass(className);
        $this.next().slideToggle();
    });
}
function showContent(btn,content,className){
    btn.click(function(event) {
        let $this = $(this);
        event.preventDefault();
        $this.toggleClass(className);
        content.fadeToggle();
    });
}  
$(function(){	
	scrollW();
	window.addEventListener('scroll', function(){
		scrollW();
	})

	$('.close-res').click(function(event) {
		event.preventDefault();
		$('html').removeClass('hide-overflow');

		$('.booking-widget').stop().animate({left:'100%'},{queue:false,duration:300});
	});

	/*MOBILE MENU NAV
	****************************************************************/
	
	//Open Navigation
	$('#menu-btn').click(function(event) {
		$('html').addClass('hide-overflow');
		$('.navigation').addClass('open');
		$('.navigation').stop().animate({left:'0px'},{duration:300});
		if($('.navigation ul li a').hasClass('.item-open')){
			$(".item-open").get(0).scrollIntoView(false);
		}
	});

	$('.close-menu').click(function(event) {
		event.preventDefault();
		$('html').removeClass('hide-overflow');
		$('.navigation').removeClass('open');
		$('.navigation').stop().animate({left:'100%'},{queue:false,duration:300});
	});

	//SUBNAVIGATION

	// Open active sub-nav on load
	$('.navigation ul.submenu li .active').closest('.inner').addClass('open')
	$('.navigation ul.submenu li .active').parents().parents('li').addClass('item-open');
	$('.navigation ul.submenu li .active').closest('.sub-sub-menu').slideDown(300, function() {}).addClass('menu-open');

	//Toggle open/close on tap
	$('.navigation li a').off('click').on('click', function(event) {
		if ( $(this).parent().children(".sub-sub-menu").length > 0 ) {

            event.preventDefault();

           	if ($(this).next().hasClass('menu-open')) {
	           	$(this).closest('.inner').removeClass('open');
	            $('.navigation ul li').removeClass('item-open');
	            $(this).parent().addClass('item-open');
                $(this).next().slideUp(300, function(){
	                if($('.navigation ul li a').hasClass('active')){
						$(".item-open").get(0).scrollIntoView(false);
					} 
                }).removeClass('menu-open');	                
            } else {
	            $(this).closest('.inner').addClass('open');
	            $('.navigation ul li').removeClass('item-open');
	            $(this).parent().addClass('item-open');
                $('.sub-sub-menu.menu-open').slideUp(300).removeClass('menu-open'); 
                $(this).next().addClass('menu-open').slideDown(300, function(){
	                if($('.navigation ul li a').hasClass('active')){
						$(".item-open").get(0).scrollIntoView(false);
					}
                });
            }
        }
    });

	//tabs sections
	const tabLinks = document.querySelectorAll("ul.js-tabs-menu li a");

	for (let i = 0; i < tabLinks.length; i++) {
	  tabLinks[i].onclick = function () {
		let target = this.getAttribute("href").replace("#", "");
		let sections = document.querySelectorAll("div.tab-content");
		let general_disclaimer = document.querySelector(".js-general_disclaimer_menus");

		for (let j = 0; j < sections.length; j++) {
		  sections[j].style.display = "none";
		}
		document.getElementById(target).style.display = "block";
		for (let k = 0; k < tabLinks.length; k++) {
		  tabLinks[k].classList.remove("active");
		}
		if(target == 'minibar'){
			general_disclaimer.style.display = "none";
		  }else{
			general_disclaimer.style.display = "block";
		  }
		this.classList.add("active");
		return false;
	  };
	}

	/*CUSTOM SCRIPTS FOR THIS SITE
	****************************************************************/

	

	$(".fancybox").fancybox();

	jQuery(document).ready(function ($) {
        $(window).scroll(function(){
            
            var scrollTop = (($('.top-content').height() + $('.intro-copy').height()) + 70);
            
            if($(window).scrollTop() >= scrollTop){
                $('ul.list-navigation').css({
                    position : 'fixed',
                    top : '70px'
                });
            }
            if($(window).scrollTop() < scrollTop){
                $('ul.list-navigation').removeAttr('style');	
            }
        });

		$('.list-navigation-dropdown select').change(function() {
			window.location = '#'+this.value;
		})
    });

	/*END OF CUSTOM SCRIPTS
	****************************************************************/

});


 //GAME DAY
 document.addEventListener('DOMContentLoaded', function () {
    searchAndClick();
  });
  
  function searchAndClick() {
    const desiredElement = document.querySelector('.js-tabs-menu a[href="#valentines-day"]');
  
    if (desiredElement) {
      desiredElement.addEventListener('click', function (event) {
        event.preventDefault(); 
        console.log('Clicked on Element');
      });
      desiredElement.click();
    } else {
      console.log('No Element in DOM');
    }
  }

  $(document).on("click", 'a[href^="#"]:not(.no-scroll)', function (event) {
    if (!this.getAttribute("href").trim().includes("#/booking/step-1") && this.getAttribute("href").trim() !== "#gameday" ) {
      event.preventDefault();
      $("html, body").animate(
        {
          scrollTop: $($.attr(this, "href")).offset().top - 200,
        },
        500
      );
    }
  });
